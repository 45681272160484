<template>
   <v-card>
     <v-container>
       <v-card-title>Change Site of Purchase</v-card-title>
       <v-layout row align-center justify-center fill-height>
         <v-flex>
           <v-autocomplete
             v-model="siteID"
             :items="sites"
             :loading="loading"
             :filter="siteFilter"
             label="Select a site"
             :rules="siteRules"
             item-text="name"
             item-value="id"
           >
             <template
               slot="item"
               slot-scope="data"
             >
               <v-list-tile>
                 <v-list-tile-title
                   v-text="data.item.name"
                 >
                 </v-list-tile-title>
                 <v-list-tile-sub-title
                   v-text="data.item.short_name"
                 >
                 </v-list-tile-sub-title>
               </v-list-tile>
             </template>
           </v-autocomplete>
         </v-flex>
       </v-layout>
     </v-container>
     <standard-actions
       @save="save"
       @cancel="cancel"
       @close="cancel"
       :save-button="{ name: 'Submit', disabled: siteID < 0 }"
     ></standard-actions>
   </v-card>
</template>

<script>
import standardActions from '../templates/standardActions'
import { GET_SITES } from '@/api/graphql/Constants/Locations'

export default {
  name: 'changeSite',
  components: {
    'standard-actions': standardActions
  },
  props: {
    itemID: {
      type: Number || String,
      required: true
    }
  },
  data () {
    return {
      siteID: null,
      siteRules: [
        e => !!e || 'Select a valid site'
      ],
      sites: [],
      loading: false
    }
  },
  apollo: {
    sites: {
      query: GET_SITES,
      watchLoading (isLoading) {
        this.loading = isLoading
      }
    }
  },
  methods: {
    cancel () {
      this.$store.dispatch('grid/resetDialog')
    },

    save () {

    },

    siteFilter (item, queryText) {
      const search = queryText.toLowerCase()
      const nameToSearch = item.name.toLowerCase()
      const shortNameToSearch = item.short_name.toLowerCase()

      return (nameToSearch || '').indexOf(search) > -1 || (shortNameToSearch || '').indexOf(search) > -1
    }
  }
}
</script>

<style scoped>

</style>
