import gql from 'graphql-tag'
import { FRAGMENT__ADDRESS__GRID } from '@/api/graphql/Constants/Addresses'

/* FRAGMENTS */

export const FRAGMENT__SITE__LIST = gql`
  fragment Site_List on Location_Site {
    id
    name
    short_name
    delivery_notes
    main_site
  }
`

export const FRAGMENT__SITE__GRID = gql`
  fragment Site_Grid on Location_Site {
    ...Site_List
    address { ...AddressGrid }
  }
  ${FRAGMENT__SITE__LIST}
  ${FRAGMENT__ADDRESS__GRID}
`

export const FRAGMENT__LOCATION__LIST = gql`
  fragment Location_List on Location_Location {
    id
    name
    site { ...Site_List }
  }
  ${FRAGMENT__SITE__LIST}
`

export const FRAGMENT__LOCATION__GRID = gql`
  fragment Location_Grid on Location_Location {
    ...Location_List
    part_type
  }
  ${FRAGMENT__LOCATION__LIST}
`

/* HELPERS */

/* QUERIES */

export const GET_LOCATIONS = gql`
  query GetLocations {
    locations: location_locations (input: {}) {
      ...Location_List
    }
  }
  ${FRAGMENT__LOCATION__LIST}
`

export const GET_LOCATIONS__GRID = gql`
  query GetLocations_Grid ($input: ManyQueryInput!) {
    location_locations (input: $input) {
      ...Location_Grid
    }
  }
  ${FRAGMENT__LOCATION__GRID}
`

export const GET_SITES = gql`
  query GetSites {
    sites: location_sites (input: { limit: 1000 }) {
      ...Site_List
    }
  }
  ${FRAGMENT__SITE__LIST}
`

export const GET_SITES__GRID = gql`
  query GetSites_Grid {
    sites: location_sites (input: { limit: 1000 }) {
      ...Site_Grid
    }
  }
  ${FRAGMENT__SITE__GRID}
`

/* MUTATIONS */

export const CREATE_LOCATION = gql`
  mutation CreateLocation ($input: Create__Location_Location__Input!) {
    Create__Location_Location (input: $input) {
      ...Location_Grid
    }
  }
  ${FRAGMENT__LOCATION__GRID}
`

export const UPDATE_LOCATION = gql`
  mutation UpdateLocation ($input: Update__Location_Location__Input!) {
    Update__Location_Location (input: $input) {
      ...Location_Grid
    }
  }
  ${FRAGMENT__LOCATION__GRID}
`

export const DELETE_LOCATION = gql`
  mutation DeleteLocation ($id: ID!) {
    Delete__Location_Location (id: $id) { id }
  }
`

export const CREATE_SITE = gql`
  mutation CreateSite ($input: Create__Location_Site__Input!) {
    Create__Location_Site (input: $input) {
      ...Site_Grid
    }
  }
  ${FRAGMENT__SITE__GRID}
`
export const UPDATE_SITE = gql`
  mutation UpdateSite ($input: Update__Location_Site__Input!) {
    Update__Location_Site (input: $input) {
      ...Site_Grid
    }
  }
  ${FRAGMENT__SITE__GRID}
`

export const DELETE_SITE = gql`
  mutation DeleteSite ($id: ID!) {
    Delete__Location_Site(id: $id) { id }
  }
`
