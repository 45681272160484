<template>
  <div>
    <tab-bar-wrapper @newRow="createPT">
    </tab-bar-wrapper>
    <div id="grid-container" class="grid-extended">
      <grid-wrapper
        :editableNodes="editableNodes"
        :contextMenuItems="contextMenuItems"
        :query="query"
        :annotations="annotations"
        :filter="filter"
        :componentName="$options.name"
        :columns="columns"
        :nested-grid="true"
        :gridMode="gridMode"
        @ready="ready"
        @setComments="setComments"
        @setMasterObject="setCurrentOrderObject"
        @cell-key-down="gridKbEventSender"
      />
    </div>
    <action-bar-wrapper
      :commentList="commentList"
      :buttonList="buttonList"
      @saveComments="saveComments"
    />
    <v-dialog
      lazy
      :width="width"
      v-model="showDialog"
      persistent
    >
      <add-parts-dialog
        v-if="dialog.component === 'addParts'"
        :orderNumber="selectedOrderNumber"
        :client="currentOrderObject.client.name"
        @save="updateRowData"
      >
      </add-parts-dialog>
      <pdf-viewer
        v-if="dialog.component === 'pdf-viewer'"
        :data="pdfData"
        :custom="pdfRules"
        @close="$store.dispatch('grid/resetDialog')"
      />
      <change-site
        v-if="dialog.component === 'change-site'"
        :itemID="selectedItemID"
      >
      </change-site>
    </v-dialog>
  </div>
</template>

<script>
import TabBarWrapper from '@/components/wrappers/tabBarWrapper.vue'
import ActionBarWrapper from '@/components/wrappers/actionBarWrapper.vue'
import GridWrapper from '@/components/wrappers/gridWrapper.vue'
import checkboxRenderer from '@/components/renderers/checkbox.vue'
import addParts from '../../components/dialogs/addParts'
import changeSite from '../../components/dialogs/changeSite'
import grid from '../../components/mixins/grid'
import columns from '../../components/mixins/columns'
import mutateOrders from '../../api/graphql/mutationsJS/mutateOrders'
import { GET_PURCHASE_FOR_PDF, GET_PURCHASES } from '@/api/graphql/Constants/Orders'
import { COLUMNS__PURCHASES } from '@/lib/agGridColumnConfiguration'
import { CONTEXT_MENU__PURCHASE_ORDERS } from '@/lib/agGridContextMenuConfigurations'
import { GridKbEventHandler } from '@/lib/eventHandlers'
import { KB_SHORTCUT__PURCHASE_ORDERS } from '@/lib/agGridKbShortCuts'
import ViewPDF from '@/components/dialogs/ViewPDF'
import { prepPdfData } from '@/lib/PdfPrep'
export default {
  name: 'purchaseOrders',
  mixins: [grid, columns, mutateOrders],
  components: {
    'tab-bar-wrapper': TabBarWrapper,
    'action-bar-wrapper': ActionBarWrapper,
    'grid-wrapper': GridWrapper,
    'add-parts-dialog': addParts,
    'change-site': changeSite,
    'pdf-viewer': ViewPDF
  },
  computed: {
    showDialog () {
      return this.dialog.app === 'pts'
    },

    dialog () {
      return this.$store.state.grid.dialog
    }
  },
  data () {
    return {
      // TODO: when receive is checked mark receive_status as true, when delete: delete part
      editableNodes: [],
      width: '800',
      frameworkComponents: {
        checkboxRenderer: checkboxRenderer
      },
      contextMenuItems: (params) => CONTEXT_MENU__PURCHASE_ORDERS(params, this.contextMenuCallback),

      commentList: {
        'internal_comment': {
          name: 'internal_comment',
          label: 'Internal',
          text: '',
          editable: true,
          show: true
        },
        'contract_comment': {
          name: 'contract_comment',
          label: 'Contract',
          text: '',
          editable: false,
          show: true
        },
        'id': false
      },

      annotations: [
        {
          name: 'unReceivedCount',
          aggr_type: 'COUNT',
          aggr_field: 'pt_items',
          aggr_filters: [{ key: 'pt_items__item__receive_status', value: false }]
        }
      ],
      filter: [],
      // query: columns => GRID__GET_PURCHASES(columns),
      query: GET_PURCHASES,

      buttonList: {
        printButton: [
          'PT',
          'Labels'
        ]
      },

      columns: [],

      // dialog variables
      selectedOrderNumber: null,
      selectedItemID: null,

      pdfData: {},
      pdfRules: []
    }
  },
  methods: {
    contextMenuCallback (params) {
      if (params.dataKey) this[params.dataKey] = params.data
      if (params.functionToRun) this[params.functionToRun](params.params)
    },

    gridKbEventSender (params) {
      GridKbEventHandler(params, KB_SHORTCUT__PURCHASE_ORDERS(params, this.contextMenuCallback), [])
    },

    /**
     * Used to update data without manually doing a refresh
     * @params data {array}
     */
    updateRowData (data) {
      if (typeof data !== typeof []) {
        data = [data]
      }
      this.gridOptions.api.updateRowData({ update: data })
      this.gridOptions.api.redrawRows(data)
    },

    ready (params) {
      this.gapi = params.api
      this.capi = params.columnApi
      this.gridOptions = params

      const sort = [{ colId: 'pt', sort: 'desc' }]
      this.gapi.setSortModel(sort)
    },

    addItems (id) {
      this.width = '80%'
      this.selectedOrderNumber = id
      this.$store.dispatch('orders/changeOrderType', { type: 'purchase' })
      this.$store.dispatch('grid/changeDialog', { app: 'pts', component: 'addParts' })
    },

    copyPT (params) {
      // TODO:
    },

    changeWH (params) {
      this.$store.dispatch('grid/changeDialog', { app: 'pts', component: 'change-site' })
    },

    createPT () {
      this.$store.dispatch('purchase/start')
    },

    async viewContract (id) {
      this.$store.dispatch('notifications/createSnackbar', {
        message: 'Grabbing data...',
        color: 'info'
      })
      try {
        const response = await this.$apollo.query({
          query: GET_PURCHASE_FOR_PDF,
          variables: { id: id }
        })
        if (response.data.purchases_purchase) {
          const prepped = prepPdfData.Purchase(response.data.purchases_purchase)
          this.pdfData = prepped.pdf
          await this.$store.dispatch('grid/changeDialog', { app: 'pts', component: 'pdf-viewer' })
          await this.$store.dispatch('notifications/hideSnackbar')
        }
      } catch (error) {
        const message = error.message ? error.message : error
        this.$store.dispatch('notifications/createSnackbar', {
          message: message,
          color: 'error'
        })
      }
    },

    async viewReleaseForm (id) {
      this.$store.dispatch('notifications/createSnackbar', {
        message: 'Grabbing data...',
        color: 'info'
      })
      try {
        const response = await this.$apollo.query({
          query: GET_PURCHASE_FOR_PDF,
          variables: { id: id }
        })
        if (response.data.purchases_purchase) {
          const prepped = prepPdfData.PurchaseRelease(response.data.purchases_purchase)
          this.pdfData = prepped.pdf
          this.pdfRules = prepped.rules
          await this.$store.dispatch('grid/changeDialog', { app: 'pts', component: 'pdf-viewer' })
          await this.$store.dispatch('notifications/hideSnackbar')
        }
      } catch (error) {
        const message = error.message ? error.message : error
        this.$store.dispatch('notifications/createSnackbar', {
          message: message,
          color: 'error'
        })
      }
    },

    keyUpChecker (event) {
      const node = this.gapi.getSelectedNodes()
      if (['INPUT', 'TEXTAREA'].indexOf(document.activeElement.nodeName) === -1) {
        if ((event.key === 'a' || event.code === 'KeyA') && node.length === 1) {
          this.addItems(node[0].data.id)
          return ''
        }
        if ((event.key === 'r' || event.code === 'KeyR') && node.length === 1) {
          if (node[0].data.status.id > 2) {
            this.$router.push({ name: 'receive', params: { id: node[0].data.id } })
            return ''
          } else {
            this.$store.dispatch('notifications/createSnackbar', {
              message: 'This order cannot be received yet.',
              color: 'warning',
              top: false,
              timeout: 4000,
              canClose: true
            })
          }
        }
        if (node.length > 1) {
          this.$store.dispatch('notifications/createSnackbar', {
            message: 'Select only one row to perform this shortcut.',
            color: 'warning',
            top: false,
            timeout: 4000,
            canClose: true
          })
        }
      }
    }
  },
  beforeMount () {
    this.columns = COLUMNS__PURCHASES
    if (this.$route.params.pt !== undefined) {
      const pt = this.$route.params.pt
      this.ptFilter = [{ key: 'id', value: pt }]
      this.$apollo.queries.purchases.refetch()
    } else {
      this.ptFilter = null
    }
  }
  // mounted () {
  //   window.addEventListener('keyup', this.keyUpChecker)
  // },
  // destroyed () {
  //   window.removeEventListener('keyup', this.keyUpChecker)
  // }
}
</script>
