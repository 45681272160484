<template>
  <v-checkbox
    class="pt-0 mt-1"
    v-model="checked"
    :color="color"
    :disabled="disabled"
    :indeterminate="color === 'error' && checked"
    hide-details
  />
</template>

<script>
import Vue from 'vue'
export default Vue.extend({
  name: 'checkbox',
  computed: {
    column () {
      return this.isDeleteColumn ? 'delete' : 'item.receive_status'
    },

    disabled () {
      const allocated = this.$_.get(this.params.data, 'item.saleItem.id', false)
      if (this.isDeleteColumn && allocated) {
        return true
      } else {
        return this.$_.get(this.params.data, this.reverseColumn)
      }
    },

    isDeleteColumn () {
      return this.params.column.colId === 'delete'
    },

    color () {
      return this.isDeleteColumn ? 'error' : 'info'
    },

    reverseColumn () {
      return !this.isDeleteColumn ? 'delete' : 'item.receive_status'
    },

    checked: {
      get () {
        return this.$_.get(this.params.data, this.column)
      },
      set (value) {
        this.params.setValue(value)
        if (value && this.isDeleteColumn) {
          this.costHolder = this.params.data.original_cost
          this.params.node.setDataValue('originalCost', 0)
        } else if (!value && this.isDeleteColumn) {
          this.params.node.setDataValue('originalCost', this.costHolder)
        }
      }
    }
  },
  data () {
    return {
      costHolder: 0
    }
  },
  methods: {
    refresh () {
      return true
    }
  }
})
</script>

<style scoped>
  .receive_status {
    color: #1E88E5;
  }

  .delete {
    color: #B71C1C;
  }
</style>
